// Use the Bulma CSS as base
@import '~bulma/sass/utilities/_all';
@import '~bulma/sass/base/_all';
@import '~bulma/sass/grid/_all';
@import '~bulma/sass/elements/table';
@import '~bulma/sass/elements/button';
@import '~bulma/sass/form/_all';
@import '~bulma/sass/components/pagination';

// Our styles
@import './styles/cssToRemove/admin.scss';

* {
  box-sizing: border-box;
}

$themeGray: #7a8d9f;

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 0;
}

body {
  height: 100%;
}

#root {
  font-family: Source Sans Pro;
  font-weight: normal;
  background-color: #f2f4f7;
  height: 100%;
  overflow: auto;
}

.app {
  height: 100%;
}

.table {
  border: 1px;
}

a {
  color: #4a90e2;
}

.table th {
  font-weight: 700;
}

/* ADMIN */
.nav-item {
  display: inline-block;
  padding: 8px;
  margin: 6px;
  font-size: 17px;
}

.nav-item.active {
  background: #17b6d4;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.input-group-append {
  display: inline-block;
}

.card {
  display: inline-block;
  width: 100%;
  background-color: white;
  padding: 1em;
  padding-top: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  border: 1px solid #00000020;
  border-radius: 5px;

  .card-header {
    font-size: 20px;
    font-weight: bold;
  }

  .admin-title {
    margin-top: 0px;
    float: left;
  }

  > .sentisis-button {
    float: right;
    margin-right: 1em;
  }
}

.card-body {
  width: 100%;
  overflow: hidden;

  table {
    width: 100%;
  }
}

.admin-entity-buttons {
  .sentisis-button {
    margin: 5px;
    height: 40px;
    line-height: 40px;
  }
}

.admin-title {
  color: #5c6b7e;
  font-weight: bold;
  font-size: 23px;
  margin-top: 30px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;

  h4 {
    font-weight: bold;
    float: left;
  }

  a {
    float: right;
  }

  .sentisis-button {
    float: right;
  }
}

.sentisis-input label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
  color: grey;
}

.admin-table {
  width: 100%;

  thead {
    border-bottom: 2px solid #00000033;
  }

  tr {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #00000024;
    line-height: 50px;
  }

  td,
  th {
    padding: 0px 0.75rem;
  }
}

.admin-table__buttons {
  text-align: center;

  .page-item {
    display: inline-block;
    margin: 0.5em 0.1em;

    &.disabled {
      .page-link {
        color: #6c757d;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6 !important;
      }
    }

    .page-link {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #007bff;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        border-color: #b3b3b3;
      }
    }
  }
}

/* MODAL */
// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
  overflow: hidden;
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 1.75rem auto;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.show & {
    transform: translate(0, 0);
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
}

// Actual modal
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: 1em;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
}

.ps-sidebar-container {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.ps-menu-icon {
  margin-right: 5px !important;
  margin-left: -1.5pc !important;
}

.ps-collapsed .ps-menu-icon {
  margin-left: 0pc !important;
}

.ps-collapsed nav ul li {
  padding: 0px 2px !important;
}
.ps-collapsed .ps-menu-button {
  padding-right: 10px !important;
  padding-left: 2px !important;
}
nav ul li a {
  border-radius: 4px !important;
}

nav ul li:nth-child(1) a:hover {
  background-color: transparent !important;
  cursor: auto;
}
nav ul li {
  padding: 0px 10px;
}
