.admin-container {
  flex: 1 0 0;
  padding-top: 20px;
  padding-bottom: 35px;
  z-index: 100;
  align-self: center;
  display: block !important;
}

// Dashboard container
.admin-dashboard-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-self: center;
}
