@import '../../../styles/cssToRemove/colors';

.empty-message {
    align-items: center;
    display: flex;
    height: 100%; 
    justify-content: center;
    min-height: 300;
    padding: 20px;
    width: 100%;
    color: $gray-dark;
    font-size: 30px;
}