.SentimentRow {
  color: #637181;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
}

.SentimentRow__sentiment {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  width: 38px;
  text-decoration: none;
  cursor: pointer;
}

.SentimentRow__sentiment.has-tooltip:hover::before {
  top: calc(-100% - 8px);
  left: calc(50% - 6px);
}

.SentimentRow__sentiment:last-child {
  margin-right: 0;
}

.SentimentRow__sentiment--positive {
  color: #68bf39;
}

.SentimentRow__sentiment--negative {
  color: #e6312e;
}

.SentimentRow__sentiment--objective {
  color: #ffae00;
}

.SentimentRow--clickable {
  cursor: pointer;
}
