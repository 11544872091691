.uppy-StatusBar {
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 400;
  color: #5c6b7e;
  background-color: white;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.23);
  z-index: 100;
  transition: height 0.2s;
  width: 460px;
  border-radius: 3px;
}

.uppy-StatusBar[aria-hidden='true'] {
  overflow-y: hidden;
  height: 0;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #009e55;
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e45b46;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-content {
  width: 100%;
  text-align: center;
  padding-left: 0;
  justify-content: center;
  color: white;
}

.uppy-StatusBar:not([aria-hidden='true']).is-waiting {
  background-color: white;
  height: 65px;
  border-top: 1px solid #7f9bb5;
}

.uppy-StatusBar-progress {
  background-color: #00a7f7;
  height: 3px;
  position: absolute;
  z-index: 101;
  transition: background-color, width 0.3s ease-out;

  &.is-indeterminate {
    background-size: 64px 64px;
    background-image: linear-gradient(
      45deg,
      #00a7f7 25%,
      transparent 25%,
      transparent 50%,
      #00a7f7 50%,
      #00a7f7 75%,
      transparent 75%,
      transparent
    );
    animation: statusBarProgressStripes 1s linear infinite;
  }
}

.uppy-u-reset.uppy-StatusBar-statusIndicator {
  display: none;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none;
}

@keyframes statusBarProgressStripes {
  from {
    background-position: 64px 0;
  }
  to {
    background-position: 0 0;
  }
}

.uppy-StatusBar-content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 103;
  padding-left: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #49555f;
  height: 100%;
}

.uppy-StatusBar-contentPadding {
  margin-right: 0.5ch;
}

.uppy-StatusBar-status {
  line-height: 1.35;
  font-weight: normal;
  letter-spacing: 0.5px;
}

.uppy-StatusBar-statusPrimary {
  font-size: 13px;
}

.uppy-StatusBar-statusSecondary {
  font-size: 11px;
  display: none;
}

.uppy-StatusBar--detailedProgress .uppy-StatusBar-statusSecondary {
  display: inline;
}

.uppy-StatusBar-statusIndicator {
  color: #49555f;
  margin-right: 15px;
  cursor: pointer;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  width: 15px;
  margin-right: 7px;
  color: white;
}

.uppy-StatusBar-actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  z-index: 104;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  width: 100%;
  position: static;
  padding: 0 15px;
}

.uppy-StatusBar-actionBtn {
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;
}

.uppy-Dashboard--wide .uppy-StatusBar-actionBtn {
  padding: 7px 10px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  font-size: 14px;
  width: 100%;
  padding: 15px 10px;
}

.uppy-Dashboard--wide
  .uppy-StatusBar.is-waiting
  .uppy-StatusBar-actionBtn--upload {
  padding: 13px 28px;
  width: auto;
}

.uppy-StatusBar-actionBtn:not(:last-child) {
  margin-right: 10px;
}

.uppy-StatusBar-actionBtn--retry {
  background-color: white;
  color: #e45b46;
}

.uppy-StatusBar-actionBtn--cancel {
  background-color: transparent;
  color: #e45b46;
}

.uppy-StatusBar-details {
  line-height: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  color: #e45b46;
  background-color: white;
  border-radius: 50%;
  position: relative;
  top: -1px;
  left: 6px;
  font-size: 10px;
  text-align: center;
  cursor: help;

  &:hover {
    cursor: help;
  }
}

.uppy-StatusBar-details:after {
  line-height: 1.3;
  word-wrap: break-word;
}
