@import '@constants/colors.scss';

.c-FancyTooltip {
  position: absolute;
  top: -30px;
  right: 25px;
  z-index: 100;
  width: 300px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.06);
  text-align: left;
}

/* Half of the tooltip */
.c-FancyTooltip.is-left {
  left: calc(100% - 137.5px);
}

.c-FancyTooltip.is-right {
  left: calc(100% + 15px);
}

.c-FancyTooltip__title {
  padding: 10px 20px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: $mermaid;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  font-family: Source Sans Pro;
}

.c-FancyTooltip__text {
  padding: 15px 20px;
  color: #7e8c9c;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
}

.c-FancyTooltip__text a {
  color: $mermaid;
}

.c-FancyTooltip__text p {
  margin: 0 0 10px 0;
}

.c-FancyTooltip__text p:last-child {
  margin: 0;
}
