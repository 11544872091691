/**
 * Only use those colors in the CSS.
 * Ask design if one doesn't fit
 */

$cloudy: #f2f4f7;
$deep_blue: #1f2b59;
$blue_ocean: #1e69b5;
$mermaid: #10d4ab;
$royal_blue: #0a569f;
$sky_blue: #4c87d9;
$violet: #8b6ec8;

$sentiment_negative: #e6312e;
$sentiment_positive: #68bf39;
$sentiment_objective: #ffae00;

$dark_gray: #4a637c;
$deep_gray: #192541;
$gray: #7a8d9f;
$light_gray: #a4afbb;
$smoky: #ccd1d8;

$background_color: $cloudy;

$error: #e6312e;
$error_dark: #c32927;

/** Todo: remove this old colors **/
$BLUECLEAR: #58d0e1;
$BLUEDEEPOCEAN: #2d559e;
$BLUEDARK: #24447e;
$BLUEDARKER: #283f59;
$BLUEOCEAN: #0094b7;
$BLUEMILD: #35c002;
$BLUEBRIGHT: #00e5f2;
$BLUETURQUOISE: #01b7af;

$GRAYNIGHT: #273f59;
$GRAYINTENSE: #7e8c9c;
$GRAYSHADE: #637181;
$GRAYMILD: #758393;
$GRAYPLAIN: #7e8c9c;
$GRAYFAINT: #a5afba;
$GRAYPALE: #ccd1d8;
$GRAYDIMMED: #e6e8ec;
$GRAYLIGHT: #f3f4f6;
$GRAYDARK: #1e2f42;

$PURPLEDARK: #7a62f0;

$PINKHOT: #d83480;

$RED_ERROR: #ea5e5b;
$RED_ERROR_DARK: #752f2d;
$RED_YOUTUBE: #af2d25;

$GREEN_SUCCESS: #72be49;
$GREEN_SUCCESS_DARK: #3f642b;
$GREEN_BRIGHT: #50e3c2;
$LIME_LIGHT: #00b7af;

$YELLOW_WARNING: #ffbb45;
$ORANGE_MEDIA: #f5a442;

$BLUE_TWITTER: #78bae9;
$BLUE_FACEBOOK: #3d5c99;
$BLUE_INSTAGRAM: #00264d;
$BLUE_INFO: #2eb8cc;
$BLUE_INFO_DARK: #4c5a6a;

$DARK_BACKGROUND: #20272d;
