/**
 * "passes trough" flex components. Useful for situation where you want
 * the child element to fill the parent which is a flex container
 */
.u-flex-trough {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

.u-tooltip {
  position: relative;
}

.u-tooltip:hover::before {
  position: absolute;
  top: 50%;
  max-width: 275px;
  left: calc(100% + 8px);
  z-index: 10;
  display: block;
  padding: 0 8px;
  background-color: #4d6680;
  color: white;
  content: attr(aria-label);
  text-transform: initial;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  transform: translateY(-50%);
  border-radius: 2px;
}

.u-tooltip--left:hover::before {
  transform: translateX(-100%);
  left: calc(-15%);
  top: 15%;
}

.u-tooltip--top:hover::before {
  top: -20px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.u-tooltip--bottom:hover::before {
  left: 50%;
  transform: translateX(-50%) translateY(50%);
}

.u-rotate {
  transform: rotate(180deg);
}
