.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner.with-flex {
  flex: 1;
}

.Spinner.with-position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Spinner.with-padding {
  margin: 25px;
}

.Spinner__svg {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Spinner__path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #3f88f8;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  0% {
    stroke: #00a7f7;
  }
  20% {
    stroke: #00a7f7;
  }
  25% {
    stroke: #3e4eb8;
  }
  45% {
    stroke: #3e4eb8;
  }
  50% {
    stroke: #00a7f7;
  }
  70% {
    stroke: #00a7f7;
  }
  75% {
    stroke: #3e4eb8;
  }
  95% {
    stroke: #3e4eb8;
  }
}
