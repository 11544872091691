@import './colors.scss';

/**
 * 1. Those properties are really important as they allow the tolltip to be
 *    in a different context than the containing elemented. The correct
 *    position is computed on render
 */
.c-SimpleTooltip {
  display: block;
  padding: 6px 10px;
  max-width: 275px;
  border-radius: 2px;
  background-color: $gray;
  color: white;
  text-transform: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.2;
  z-index: 100;
  white-space: normal; /* 1 */
  position: fixed; /* 1 */
}
