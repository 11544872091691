@import '@constants/colors.scss';

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0px 0px 2px 10px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.caret-right {
  transform: rotate(-90deg);
}

.c-ButtonDropdown {
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  flex: 1;
  cursor: pointer;
  font-size: 14px;
}

.c-ButtonDropdown a {
  display: block;
  outline: 0;
  color: inherit;
  text-decoration: none;
}

.c-ButtonDropdown li {
  cursor: pointer;
  height: 45px;

  padding: 12px 12px;
  font-weight: 600;
  white-space: nowrap;
  position: relative;
  /* prevent links from randomly breaking onto new lines */
}
/* Prevent the focus on the dropdown toggle when closing dropdowns */
.c-ButtonDropdown-toggle:focus {
  outline: 0;
}
/* The dropdown menu (ul) */
.c-ButtonDropdown-menu {
  position: absolute;
  cursor: default;
  top: calc(100% + 5px);
  z-index: 100;
  display: none;
  /* none by default, but block on "open" of the menu */
  min-width: 110px;
  margin: 0;
  /* override default ul */
  border-radius: 2px;
  list-style: none;
  font-size: 14px;
  text-align: left;
  /* Ensures proper alignment if parent has it changed (e.g., modal footer) */
  background-color: #fff;
  border: 1px solid $smoky;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: $gray;

  &.c-ButtonDropdown-menu--lefty {
    left: 0px;
  }
}

.c-ButtonDropdown-submenu {
  position: absolute;
  left: 100%;
  background: #fff;
  list-style: none;
  padding: 4px 0;
  min-width: 105px;
  border: 1px solid $smoky;
  top: -1px;
  box-sizing: border-box;
  color: $gray;
}

.c-ButtonDropdown-submenu.is-left {
  left: 0;
  transform: translateX(-100%);
}

.c-ButtonDropdown-menu li:hover {
  color: $mermaid;
}

/* Open state for the dropdown */
.is-open {
  & > .c-ButtonDropdown-menu {
    display: block;
  }
  & > a {
    outline: 0;
  }

  svg {
    color: $mermaid;
  }
}

.c-ButtonDropdown.has-divider .c-ButtonDropdown-menu:first-child {
  padding-bottom: 0px;
}

.c-ButtonDropdown.has-divider
  .c-ButtonDropdown-menu
  > li:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $smoky;
  content: '';
}
