$white: #ffffff;
$gray: #f8f9fa;
$gray-light: #f2f4f7;
$gray-dark: #a4afbb;
$light_gray: #a4afbb;
$green: #6cdb78;
$pastel-green: #6edb6e;
$red: #e6312e;
$red-light: #f5817f;
$orange: #ffae00;
$Golden-yellow: #ffbe4c;
$green: #6edba4;
$red: #f5817f;
$orange: #ffbe4c;
$black: #000;
$turquoise: #10d4ab;

$shadow-color: rgba($black, 0.04);
$text-color: $gray-dark;
$cta: $turquoise;
// TODO rename colors
$title: #4a637c;
$subtitle: #637181;

$sentiment-palette: (
  'positive': $green,
  'negative': $red,
  'objective': $orange,
  'unknown': $gray-dark,
);

$nps-palette: (
  'promoters': $green,
  'detractors': $red,
  'passive': $orange,
);

// Rethink architecture based in css vars, move to utils perhaps
.color {

  @each $key,
  $color in $sentiment-palette {
    &-#{$key} {
      color: $color !important;
    }
  }
}

.nps {

  @each $key,
  $color in $nps-palette {
    &-#{$key} {
      color: $color !important;
    }
  }
}

.bg-color {

  @each $key,
  $color in $sentiment-palette {
    &-#{$key} {
      background-color: $color !important;
    }
  }

  @each $key,
  $color in $nps-palette {
    &-#{$key} {
      background-color: $color !important;
    }
  }
}

.bg-color-nps {

  @each $key,
  $color in $nps-palette {
    &-#{$key} {
      background-color: $color !important;
    }
  }
}