@import '@constants/colors.scss';

.c-InfoTooltip {
  position: relative;
  padding: 0;
  width: 18px;
  height: 18px;
  outline: none;
  border: none;
  background: none;
  text-align: right;
  color: $light_gray;
  opacity: 0.5;
  cursor: pointer;
}

.c-InfoTooltip:active,
.c-InfoTooltip:hover {
  color: $mermaid;
  opacity: 1;
}

.c-InfoTooltip svg {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  pointer-events: none;
}

.c-InfoTooltip-container {
  position: relative;
  z-index: 900;
}

.c-InfoTooltip--is-visible {
  color: $mermaid;
  opacity: 1;
}

.c-InfoTooltip-appear {
  position: relative;
  z-index: 100;
  opacity: 0.01;
}

.c-InfoTooltip-appear.c-InfoTooltip-appear-active {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.c-InfoTooltip-enter {
  opacity: 0.01;
}

.c-InfoTooltip-enter.c-InfoTooltip-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.c-InfoTooltip-leave {
  opacity: 1;
}

.c-InfoTooltip-leave.c-InfoTooltip-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}

.c-InfoTooltip--is-element {
  display: inline-flex;
  margin: 0;
  opacity: 1;
  width: auto;
  height: auto;
}
