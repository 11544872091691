@import '@constants/colors.scss';

/**
 * For a usage reference, look at the storybook.
 */

.Field,
.Field * {
  box-sizing: border-box;
}

.Field {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.Field__label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: $gray;
  margin-bottom: 3px;
}

.Field__counter {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: 500;
  color: $sky_blue;
}

.Field__count {
  margin-right: 10px;
  background-color: $sky_blue;
  line-height: 16px;
  font-size: 12px;
  color: white;
  font-weight: 600;
  padding: 0 4px;
  padding-bottom: 1px;
  border-radius: 2px;
  position: relative;
  top: -1px;
}

.Field__control {
  position: relative;
}

.Field__input {
  width: 100%;
  height: 45px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 600;
  color: $dark_gray;
  border-radius: 1px;
  background-color: white;
  border: 1px solid $smoky;
  outline: none;
  padding: 0 20px;
  border-radius: 1px;
  box-sizing: border-box;
}

.Field__input:hover {
  border-color: $light_gray;
}

.Field__input:focus,
.Field__input:active {
  background-color: $cloudy;
  border-color: $light_gray;
}

.Field__input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: $light_gray;
}
.Field__input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  color: $light_gray;
}
.Field__input::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  color: $light_gray;
}

.Field__input:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.Field__label-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.Field__info {
  line-height: 1.5;
  font-size: 14px;
  color: $light_gray;
  font-weight: 500;
}

.Field__error {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: $sentiment_negative;
  margin-top: 3px;
}

.Field__error p {
  margin: 0;
}
.Field__error a {
  color: $sentiment_negative;
}

.Field__description {
  color: $gray;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  margin-top: 3px;
}

.is-error .Field__label {
  color: $sentiment_negative;
}

.is-error .Field__input,
.is-error .Field__input:hover,
.is-error .Field__input:active,
.is-error .Field__input:focus,
.is-error.Field .Select-control {
  border-color: $sentiment_negative;
}

.is-disabled .Field__label {
  opacity: 0.5;
}

.Field__checkbox-label {
  display: flex;
}

.Field__checkbox-label:hover .Field__label {
  cursor: pointer;
  color: $sky_blue;
}

/* Hide the real checkbox */
.Field__checkbox input[type='checkbox'] {
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.Field__checkbox-faux {
  height: 15px;
  width: 15px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid #dfe1e6;
  line-height: 0;
  position: relative;
  cursor: pointer;
}

.Field__checkbox-label .Field__label {
  margin-left: 10px;
  margin-bottom: 0;
  line-height: 17px;
}

.Field__checkbox input:checked + .Field__checkbox-label .Field__checkbox-faux {
  background-color: $sky_blue;
  border-color: $sky_blue;
}

.Field__checkbox
  input:checked
  + .Field__checkbox-label
  .Field__checkbox-faux::before {
  content: '✓';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 14px;
  font-size: 12px;
  font-weight: 800;
  color: white;
  right: 0;
  text-align: center;
}

.Field__checkbox input:checked + .Field__checkbox-label .Field__label {
  color: $sky_blue;
}

.Field .Select-control {
  height: 45px;
  border: 1px solid $smoky;
  padding: 0 20px;
  border-radius: 1px;
  line-height: 44px;
  font-size: 14px;
}

.Field .Select--multi.has-value .Select-control {
  padding-left: 10px;
}

.Field .Select--multi .Select-input {
  margin-left: 0;
}

.Field .Select-placeholder {
  padding: 0 20px;
  color: $light_gray;
  ::first-letter {
    text-transform: capitalize;
  }
}

.Field .has-value .Select-placeholder {
  color: $dark_gray;
  font-weight: 600;
}

.Field .Select-value-label {
  color: $dark_gray;
  font-weight: 600;
}

.Field .Select-input > input {
  color: $dark_gray;
  font-weight: 600;
}

.Field .Select-menu-outer {
  border-radius: 1px;
  top: auto;
  border: 1px solid $light_gray;
  border-top: none;
}

.Field .Select.is-open .Select-control {
  border: 1px solid $light_gray;
}

.Field .Select-option {
  height: 45px;
  line-height: 44px;
  padding: 0px 0px 10px 20px;
  font-size: 14px;
  color: $gray;
  border-top: 1px solid $cloudy;
}

.Field .Select-option.is-focused {
  background-color: $cloudy;
  color: $sky_blue;
}

.Field .Select-option.is-selected {
  color: $sky_blue;
  font-weight: 600;
}

.Field .Select-control:hover {
  border-color: $light_gray;
}

.Field .Select--multi .Select-control {
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
}

.Field .Select-multi-value-wrapper {
  display: flex;
}

.Field .Select--multi .Select-multi-value-wrapper {
  display: flex;
  flex-flow: row wrap;
  line-height: 1.5;
  align-items: center;
  padding: 5px 0;
  flex: 1;
}

.Field .Select--multi .Select-value {
  height: 25px;
  align-items: stretch;
  display: flex;
  background-color: $dark_gray;
  margin: 0;
  border: 0;
  border-radius: 1px;
  margin-right: 5px;
  color: white;
  stroke: white;
}

.Field .Select--multi .Select-value-icon {
  height: 25px;
  width: 23px;
  line-height: 25px;
  font-size: 20px;
  order: 2;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.15);
  border: 0;
  display: inline-block;
  padding: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.Field .Select--multi .Select-value-icon:hover,
.Field .Select--multi .Select-value-icon:focus {
  color: white;
}

.Field .Select--multi .Select-value-label {
  padding: 0 8px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  border-radius: 1px;
  color: white;
  font-weight: 500;
}

.Field .categories.Select--multi .Select-value {
  background-color: $blue_ocean;
}

.Field .Select--multi .Select-placeholder {
  line-height: 44px;
}

.Field .Select-arrow {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

/**
 * Button
 */

.Field__button {
  display: inline-block;
  border: 0;
  outline: 0;
  background-color: $mermaid;
  font-size: 13px;
  ::first-letter {
    text-transform: uppercase;
  }
  text-decoration: none;
  color: white;
  font-weight: 600;
  text-align: center;
  min-width: 120px;
  padding: 0 20px;
  line-height: 44px;
  height: 45px;
  position: relative;
  border-radius: 2px;
}

.Field__button:hover::before,
.Field__button:active::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: ' ';
  background-color: rgba(255, 255, 255, 0.25);
}

.Field__button:active::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: ' ';
  background-color: rgba(0, 0, 0, 0.15);
}

.Field__button:disabled::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: ' ';
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}

.Field__button:disabled {
  cursor: default;
}

.Field__button--secondary {
  background-color: $light_gray;
}

.Field__button--warning {
  background-color: $sentiment_negative;
}

.Field__button-message {
  display: block;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  font-weight: 500;
}

.Field__button + .Field__button-message {
  color: $mermaid;
}
.Field__button--secondary + .Field__button-message {
  color: $light_gray;
}
.Field__button--warning + .Field__button-message {
  color: $sentiment_negative;
}

.Field__button svg {
  width: 12px;
  height: 12px;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.Field__button--small {
  height: 35px;
  line-height: 35px;
  border-radius: 2px;
  text-transform: capitalize;
  font-weight: 500;
}
