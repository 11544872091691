@import '@constants/colors.scss';

// Modify the variables:
$select-input-border-color: #eaecf7;
$select-input-border-radius: 2px;
$select-input-border-focus: #4c87d9;
$select-input-box-shadow-focus: none;
$select-input-border-width: 2px;
$select-input-height: 45px;
$select-input-placeholder: #7f9bb5;
$select-text-color: #49555f;
$select-input-hover-box-shadow: none;

$select-padding-vertical: 10px;
$select-padding-horizontal: 10px;

// menu options
$select-option-color: $select-text-color;
$select-option-focused-color: $select-input-border-focus;
$select-option-focused-bg: #f2f4f7;
$select-option-selected-bg: $select-option-focused-bg;

// clear "x" button
$select-clear-size: 18px;
$select-clear-color: $select-input-placeholder;

// arrow indicator
$select-arrow-color: $select-clear-color;
$select-arrow-color-hover: $select-text-color;

// multi-select item
$select-item-gutter: 7px;
$select-item-padding-vertical: 4px;
$select-item-padding-horizontal: 10px;
$select-item-font-size: 14px;

.c-FiltersBar,
.c-FiltersBar * {
  box-sizing: border-box;
}

.c-FiltersBar {
  z-index: 10;
  background-color: $sky_blue;
  :focus {
    outline: none;
  }
}

.c-FiltersBar abbr {
  text-decoration: none;
}

.c-FiltersBar .Spinner {
  transform: scale(0.4) translateY(14px);
  position: absolute;
  top: 0;
  left: 0;
}

/**
 * Date Filters styles
 */

.c-FiltersBar-DatePicker {
  margin-left: auto;
  display: flex;
  height: 50px;
  width: 30%;
  min-width: 280px;
}

/**
 * Filters Select styles
 */

.c-FiltersBar-Select {
  display: flex;
  align-items: center;
  height: 65px;
  width: 50%;
  appearance: none;
  border: none;
  background-color: transparent;
  border-right: rgba(255, 255, 255, 0.123) 1px solid;
  &.is-open {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.c-FiltersBar-Select__caret {
  display: block;
  margin: 0px 20px;
  border-width: 6px 6px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.4) transparent transparent;
  content: ' ';
}
.c-FiltersBar-Select__caret.is-open {
  transform: rotate(180deg) !important;
  opacity: 1;
}

.c-FiltersBar-Select:focus .c-FiltersBar-Select__label-icon {
  opacity: 1;
}

.c-FiltersBar-Select__label {
  display: flex;
  padding-left: 25px;
  color: white;
  font-weight: 600;
  line-height: 19px;
  font-size: 15px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.c-FiltersBar-Select__label-text {
  display: inherit;
}

.c-FiltersBar-Select__label-icon {
  margin-right: 20px;
  opacity: 0.35;
}

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}

@-moz-keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@-o-keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
